<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="h4 semiBold"
          >Solicitudes pendientes de Nuevos Usuarios</span
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="w-100 d-flex justify-space-between">
          <div class="d-flex">
            <v-text-field
              v-model="text_search.text"
              :prepend-inner-icon="text_search.icon"
              :label="text_search.label"
              name="rut_filtro"
              dense
              outlined
            >
              <!-- :rules="text_search.rules" -->
            </v-text-field>
            <!-- <TextFieldRut
              :text="text_search.text"
              :prependIcon="text_search.icon"
              name="rut_filtro"
            /> -->
            <v-btn
              color="primary"
              class="ml-6 text-none"
              background-color="white"
              style="backgroundcolor: red"
              @click="Buscar()"
              :loading="searchLoading"
              rounded
              width="130"
            >
              Buscar
            </v-btn>
          </div>
          <!-- <div>
            <v-btn
              color="primary"
              to="/personas/register-new-user"
              class="text-none"
              text
            >
              <v-icon left>mdi-account-plus-outline</v-icon>
              <span class="ml-3"> Registrar nuevo usuario </span>
            </v-btn>
          </div> -->
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SimpleTable
          :items="Personas"
          :search="search"
          :headers="headers_two"
          :footer="Personas.length > 9 ? true : false"
          :ActionButton="ActionShield"
          :ActionButton2="ActionReject"
          :ActionButton3="ActionApprove"
        />
      </v-col>
    </v-row>

    <Modal :open="EditModal" :userdata="userData" />
    <Reject :open="RechazarModal" :userdata="userData" />
    <Approve :open="ApproveModal" :userdata="userData" />
  </v-container>
</template>
<script>
import SimpleTable from "@/components/Tables/SimpleTable";
import Modal from "@/components/Modal/Modal-Authorize";
import Reject from "@/components/Modal/Modal-Reject-user";
import Approve from "@/components/Modal/Modal-Approve-user";
//import TextFieldRut from "@/components/Inputs/TextFieldRut";

export default {
  name: "ListadoAprobacionPersonas",
  components: {
    SimpleTable,
    Modal,
    Reject,
    Approve,
    //TextFieldRut,
  },
  data() {
    return {
      text_search: {
        placeholder: "Ingresa un rut",
        text: "",
        search: "test",
        label: "Nro. Documento",
        icon: "mdi-magnify",
        tipo: "rut",
        //rules: [(v) => v.replace(/^0+|[^0-9kK]+/g, "") || "Número de Documento"]
      },
      searchLoading: false,
      search: "",
      userdata: "",
      action: "",
      headers_two: [
        {
          text: "Nro. Documento",
          value: "usuario",
          width: "10%",
          align: "left",
        },
        {
          text: "Usuario",
          value: "nombre_largo",
          width: "15%",
          align: "left",
        },
        {
          text: "Correo",
          value: "correo_electronico",
          width: "15%",
          align: "left",
        },
        {
          text: "Canal",
          value: "canal_descripcion",
          width: "10%",
          align: "left",
        },
        {
          text: "Sucursal",
          value: "sucursal_descripcion",
          width: "10%",
          align: "left",
        },

        {
          text: "Fecha de ingreso",
          value: "fecha_creacion",
          width: "10%",
          align: "left",
        },
        // { text: "Estado", value: "estado", width: "10%", align: "left" },
        {
          text: "Aprobar",
          value: "approve",
          width: "7%",
          align: "center",
          sortable: false,
        },
        {
          text: "Rechazar",
          value: "reject",
          width: "7%",
          align: "center",
          sortable: false,
        },
        {
          text: "Cambiar canal o sucursal",
          value: "shield",
          width: "7%",
          align: "center",
          sortable: false,
        },
      ],
      Rechazarmodal: null,
      /* openModalChannel: false, */
    };
  },
  methods: {
    ActionShield(item) {
      this.$store.dispatch("EditModal", item);
    },
    ActionReject(item) {
      this.$store.dispatch("RechazarModal", item);
    },
    ActionApprove(item) {
      this.$store.dispatch("ApproveModal", item);
    },
    Buscar() {
      this.search = this.text_search.text.replace(/[^A-Z0-9]/gi, "");
    },
  },
  mounted() {
    this.$store.dispatch("GetAllUsers");
  },
  computed: {
    Personas() {
      return this.$store.getters.Personas.filter((it) => it.estado === "PEN");
    },

    userData() {
      return this.$store.getters.User;
    },

    RechazarModal() {
      return this.$store.getters.RechazarModal;
    },
    ApproveModal() {
      return this.$store.getters.ApproveModal;
    },
    EditModal() {
      return this.$store.getters.EditModal;
    },
  },
};
</script>

<style scoped>
@import "../../../assets/CSS/ServiceStyle.css";

:deep(.v-input__slot) {
  background-color: white !important;
}
</style>
